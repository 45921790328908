<template>
  <div>
      <div class="glo-nav">数据走势</div>
      <div :class="'glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <div>
          <div class="title"><i class="fa fa-area-chart"></i>表面倾斜</div>
          <div v-if="loading">
              <van-loading type="spinner" vertical>加载中</van-loading>
          </div>
          <div v-else>
              <canvas id="xaxisangleChart"></canvas>
          </div>
        </div>
        <div>
          <div class="title"><i class="fa fa-flag-checkered"></i>地表位移</div>
          <div v-if="loading">
              <van-loading type="spinner" vertical>加载中</van-loading>
          </div>
          <div v-else>
              <canvas id="vibampChart"></canvas>
          </div>
        </div>
        <tabbar />
      </div>
  </div>
</template>

<script>
const F2 = require('@antv/f2');
import tabbar from '@/components/slope/tabbar3.vue'
export default {
  components:{
      tabbar
  },
  props: ['magbot'],
  data(){
    return{
        vibampArr:[],
        xaxisangleArr:[],
        bridgeCode:'',
        loading:true
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    getChartInfo(){
       this.$api.SLOPE.statChart({
         bridgeCode:this.bridgeCode
       }).then( d =>{
          var xaxisangleArr = []; // 地表倾斜
          var vibampArr = []; // 地表位移
          for(let i=0;i<d.length;i++){
              d[i].date.forEach((e,index)=>{
                  xaxisangleArr.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      xaxisangle:parseFloat(d[i].xaxisangle[index]),
                      name:d[i].name
                  });
                  vibampArr.push({
                    date:e.substring(0,e.lastIndexOf(':')),
                    vibamp:parseFloat(d[i].vibamp[index]),
                    name:d[i].name
                  });
              })
          }
          this.xaxisangleArr = xaxisangleArr;
          this.vibampArr = vibampArr;
          this.loading = false;
          this.showChart();
       })
    },
    showChart(){
      this.$nextTick(()=>{
          // 生成倾斜图
          const chart1 = new F2.Chart({
            id: 'xaxisangleChart',
            pixelRatio: window.devicePixelRatio
          });
          chart1.source(this.xaxisangleArr);
          chart1.scale('date', {
            type: 'timeCat',
            mask: 'HH:mm',
            tickCount: 6,
            range:[0,1]
          });
          chart1.scale('xaxisangle', {
            tickCount: 6
          });
          chart1.axis('date', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          
          chart1.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart1.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart1.get('legendController').legends.top[0];
              legend.setItems(chart1.getLegendItems().country);
            }
          });
          chart1.line({
            connectNulls: true // 将空数据连接
          }).shape('smooth').position('date*xaxisangle').color('name');
          chart1.render();

          // 生成位移图
          const chart2 = new F2.Chart({
            id: 'vibampChart',
            pixelRatio: window.devicePixelRatio
          });
          chart2.source(this.vibampArr);
          chart2.scale('date', {
            type: 'timeCat',
            mask: 'HH:mm',
            tickCount: 6,
            range:[0,1]
          });
          chart2.scale('vibamp', {
            tickCount: 5
          });
          chart2.axis('date', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          chart2.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart2.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart2.get('legendController').legends.top[0];
              legend.setItems(chart2.getLegendItems().country);
            }
          });
          chart2.line().position('date*vibamp').color('name');
          chart2.render();
      })
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
      this.bridgeCode = window.sessionStorage.getItem('bridgeCode');
      this.bridgeCode ? this.getChartInfo() : void 0;
  }
}
</script>

<style lang="scss" scoped>
.glo-nav{
  background: linear-gradient(#d9e2de 0%, #35a0b1 85%);
}
#xaxisangleChart{width: 100%;height: 18rem;}
#vibampChart{width: 100%;height: 18rem;}
.title{
  text-align: left;font-size: .95rem;line-height:2;padding-left: 4%;
  .fa{color: #6cc9d8;font-size: 1.2rem;padding-right: 2%;}
}
</style>